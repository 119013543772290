.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  gap: 50px;

  @media (max-width: 799px) {
    gap: 20px;
    margin: 20px 0;
  }
}

.upperSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > div {
    border-radius: var(--border-radius);
    background-color: var(--background-medium);
    backdrop-filter: var(--backdrop-filter-light);
    max-width: 30%;
    padding: 48px;
    text-align: center;
    flex-wrap: wrap-reverse;

    @media (max-width: 799px) {
      max-width: 70%;
      padding: 30px;
    }
  }

  & > img {
    border-radius: var(--border-radius);
    margin-left: -50px;
    max-width: 35%;
    z-index: -1;
    outline: 4px solid var(--background-medium);
    outline-offset: -4px;

    @media (max-width: 799px) {
      margin-left: 0;
      max-width: 70%;
    }
  }

  @media (max-width: 799px) {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

.middleSection {
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  color: var(--font-light-color);
  outline: 4px solid var(--background-medium);
  outline-offset: 5px;
  max-width: 50%;
  padding: 48px;
  text-align: justify;

  & > p {
    margin: 20px 0 0;
  }

  @media (max-width: 799px) {
    max-width: 70%;
    padding: 30px;
  }
}

.bottomSection {
  border-radius: var(--border-radius);
  background-color: var(--background-medium);
  backdrop-filter: var(--backdrop-filter-light);
  max-width: 60%;
  padding: 48px;
  text-align: justify;

  & > p {
    margin: 20px 0 0;
  }

  @media (max-width: 799px) {
    max-width: 70%;
    padding: 30px;
  }
}

.macaron {
  max-width: 25%;
  
  @media (max-width: 799px) {
    max-width: 50%;
  }
}
