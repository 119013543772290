.packContainer {
  border-radius: var(--border-radius);
  background-color: var(--background-medium);
  min-width: 46%;
  max-width: 46%;
  padding: 48px;
  text-align: center;
  max-width: 60%;

  & > h2 {
    text-transform:uppercase;
    font-size: var(--font-size-xxl);
    font-weight: 300;
    margin-top: 0;

    @media (max-width: 799px) {
      font-size: var(--font-size-xl);
    }
  }

  & > p {
    margin: 30px 0;

    @media (max-width: 799px) {
      margin: 15px 0;
    }
  }

  @media (max-width: 799px) {
    padding: 30px;
    min-width: 0;
    max-width: 70%;
  }
}

.packBox {
  outline: 4px solid var(--primary-color);
  border-radius: var(--border-radius);
  padding: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @media (max-width: 799px) {
    gap: 5px;
  }

  & > div {
    & > p {
      text-align: center;
      margin: 10px 0;
    }

    & > h3 {
      margin: 5px 0;
      font-size: var(--font-size-base);
      font-weight: bold;
      text-decoration: underline;
      color: var(--primary-color)
    }
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--primary-color);
}

.price {
  margin: 5px 0;
}
