.titleContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  background-color: var(--background-light);
  backdrop-filter: var(--backdrop-filter-light);

  @media (max-width: 799px) {
    display: none;
  }
}

.sides {
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  max-width: 20%;
}

.macaron {
  max-width: 150px;
}

.icon {
  cursor: pointer;
}

.logo {
  max-width: 320px;
}

