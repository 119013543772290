:root {
  /* Couleurs */
  --primary-color: #ba7272e6;
  --background-light: rgba(255, 255, 255, 0.5);
  --backdrop-filter-light: blur(10px);
  --background-medium: #e8dddbe6;
  
  /* Typographie */
  --font-family-main: Helvetica, Arial, sans-serif;
  --font-size-base: 16px;
  --font-size-base-mobile: 12px; 
  --font-size-xl: 24px;
  --font-size-xxl: 32px;
  --font-light-color: white; 
  --font-light-inactive: rgba(255, 255, 255, 0.5);

  /* Lignes */
  --line-height-base: 1.5rem;
  --line-height-base-mobile: 1rem;
  
  /* Espacements */
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;
  
  /* Bordures */
  --border-radius: 4px;
  --border-width: 1px;
  --border-color: #dee2e6;
}
