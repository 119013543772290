.topFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  background-color: var(--background-light);
  backdrop-filter: var(--backdrop-filter-light);

  @media (max-width: 799px) {
    height: 32px;
    justify-content: center;
  }
}

.sides {
  display: flex;
  flex: 0.2;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);

  @media (max-width: 799px) {
    display: none;
  }
}

.macaron {
  object-fit: cover;
  max-width: 40%;
}

.icon {
  cursor: pointer;
}

.botFooter {
  text-align: center;
  padding: 5px;
  color: var(--font-light-color);
  font-weight: bold;
  background-color: var(--primary-color);
  font-size: var(--font-size-base);

  @media (max-width: 799px) {
    font-size: var(--font-size-base-mobile);
  }
}

.link {
  margin-left: 5px;
  color: var(--font-light-color);
}
