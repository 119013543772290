.content {
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  gap: 50px;

  @media (max-width: 799px) {
    gap: 20px;
    margin: 20px 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topImg {
  min-width: 50%;
  border-radius: var(--border-radius);
  
  @media (max-width: 799px) {
    width: 90%;
  }
}

.bottomImg {
  border-radius: var(--border-radius);
  margin-top: -25px;
  z-index: -1;
  
  @media (max-width: 799px) {
    width: 90%;
  }
}

.topText {
  border-radius: var(--border-radius);
  background-color: var(--background-medium);
  max-width: 50%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  text-align: justify;
  margin-top: -50px;

  @media (max-width: 799px) {
    margin-top: 20px;
    max-width: 70%;
    padding: 30px;
  }

  & > p {
    margin: 0;
  }

}

.middleText {
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  color: var(--font-light-color);
  display: flex;
  flex-direction: column;
  text-align: justify;
  padding: 48px;
  margin-left: -25%;

  & > h3 {
    margin-bottom: 0;
  }

  @media (max-width: 799px) {
    text-align: left;
    margin-left: 0;
    max-width: 70%;
    padding: 30px;
  }
}

.bottomText {
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  color: var(--font-light-color);
  max-width: 50%;
  padding: 48px;
  margin-top: -30px;
  line-height: var(--line-height-base);
  text-align: justify;

  @media (max-width: 799px) {
    margin-top: -10px;
    max-width: 70%;
    padding: 30px;
  }
}
