.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  outline: 4px solid var(--background-medium);
  outline-offset: 5px;
  width: 50%;
  padding: 48px;
  text-align: center;
  margin: 50px auto;

  & > h2 {
    color: var(--font-light-color);
  }
    
  & > h3 {
    color: var(--font-light-color);
  }

  @media (max-width: 799px) {
    min-width: 80%;
    padding: 30px;
  }
}

.formSection {
  margin: 20px 0;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 799px) {
    margin: 10px 0;
    gap: 5px;
  }
  
  & > label {
    font-weight: 200;
    color: var(--font-light-color);
  }
  
  & > input {
    border-radius: var(--border-radius);
    background-color: var(--background-light);
    border: none;
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: var(--font-size-base);

    @media (max-width: 799px) {
      height: 30px;
    }
  }
  
  & > textarea {
    border-radius: var(--border-radius);
    border: none;
    background-color: var(--background-light);
    width: 100%;
    min-height: 200px;
    font-size: var(--font-size-base);

    @media (max-width: 799px) {
      min-height: 150px;
    }
  }
  
  & > input::placeholder,
  & > textarea::placeholder {
    text-align: center;
    font-size: 16px; 
    font-style: italic; 

    @media (max-width: 799px) {
      font-size: 12px;
    }
  }

  & > input:focus,
  & > textarea:focus {
    outline: none;
  }
}
