.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
  gap: 50px;

  @media (max-width: 799px) {
    gap: 20px;
    margin: 20px 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.upperLabel {
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  color: var(--font-light-color);
  display: flex;
  flex-direction: column;
  text-align: justify;
  padding: 24px 48px;
  margin-left: -40%;

  & > h3 {
    margin-bottom: 0;
  }

  @media (max-width: 799px) {
    margin-left: 0;
    padding: 12px 30px;
  }
}

.upperImg {
  border-radius: var(--border-radius);
  width: 67%;
  margin-top: -40px;
  z-index: -1;

  @media (max-width: 799px) {
    margin-top: -5px;
    width: 85%
  }
}
