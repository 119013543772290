.prestationContainer {
  border-radius: var(--border-radius);
  background-color: var(--background-medium);
  max-width: 60%;
  padding: 48px;
  text-align: center;

  & > h2 {
    text-transform:uppercase;
    font-size: var(--font-size-xxl);
    font-weight: 300;
    margin-top: 0;

    @media (max-width: 799px) {
      font-size: var(--font-size-xl);
    }
  }

  & > p {
    margin: 0;

    @media (max-width: 799px) {
      text-align: justify;
    }
  }

  @media (max-width: 799px) {
    padding: 30px;
    max-width: 70%;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--primary-color);
}


.prestationDetails {
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  gap: 30px;
  flex-wrap: wrap;

  @media (max-width: 799px) {
    margin: 20px 0;
    gap: 20px;
  }
}

.prestationBox {
  outline: 4px solid var(--primary-color);
  border-radius: var(--border-radius);
  padding: 48px;
  text-align: center;
  flex: 1;

  @media (max-width: 799px) {
    padding: 30px;
    min-width: 60%;
  }

  & > p {
    text-align: justify;
  }

  & > h5 {
    color: unset;
    font-style: normal;
    font-weight: bold;
  }

  & > ul {
    padding: 0;

    & > li {
      list-style-type: none;
      line-height: var(--line-height-base);
    }
  }
}
