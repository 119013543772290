.name {
  font-weight: bold;
  color: var(--font-light-inactive);
  padding: 8px;

  &:hover {
    cursor: pointer;
    color: var(--font-light-color);
  };
}

.activeName {
  color: var(--font-light-color);
}

.menu.MuiMenu-root .MuiPaper-root {
  background-color: var(--primary-color);
  color: var(--font-light-color);
}

.navLink {
  padding: 8px;
  font-weight: bold;
  text-decoration: none;
  color: var(--font-light-inactive);

  &:hover {
    color: var(--font-light-color);
  };
}

.activeNavLink {
  color: var(--font-light-color);
}
