body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/public/assets/pictures/texture-peinture-rose-terne-ombre-feuille.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  line-height: var(--line-height-base);

  @media (max-width: 799px) {
    font-size: var(--font-size-base-mobile);
    line-height: var(--line-height-base-mobile);
}
}

h2 {
  margin: 5px 0;
  font-size: var(--font-size-base);
  font-weight: bold;

  @media (max-width: 799px) {
    font-size: var(--font-size-base-mobile);
  }
}

h3 {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 48px;
  font-weight: lighter;

  @media (max-width: 799px) {
    margin-bottom: 20px;
    font-size: 24px;
  }
}

h4 {
  margin: 40px 0;
  font-weight: bold;
  font-size: var(--font-size-base);
  
  @media (max-width: 799px) {
    margin: 20px 0;
    font-size: var(--font-size-base-mobile);
  }
}

h5 {
  margin: 5px 0;
  font-style: italic;
  color: var(--primary-color);
  font-size: var(--font-size-base);

  @media (max-width: 799px) {
    font-size: var(--font-size-base-mobile);
  }
}
