.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background-color: var(--primary-color);
  position: sticky;
  top: 0;
  z-index: 10;

  @media (max-width: 799px) {
    font-size: 16px;
    flex-direction: column;
    height: fit-content;
    padding: 0 10px;
  }
}

.mobileMenuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mobileMenuOpen {
  padding: 0 10px 10px 10px;
}

.mobileTitle {
  text-align: left;
}

.menuItems {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 48px;
  
  & > * {
    min-width: fit-content;
  }
  
  @media (max-width: 799px) {
    gap: 0;
    flex-wrap: wrap;
    min-width: 100%;
  }
}

.section {
  padding: 8px;
  font-weight: bold;
  text-decoration: none;
  color: var(--font-light-inactive);
  width: 100%;

  &:hover {
    color: var(--font-light-color);
  };
}

.activeSection {
  color: var(--font-light-color);
}
