.cocoButton {
  background: none;
  border: none;
  outline: none;
  outline: 4px solid var(--background-medium);

  font-size: var(--font-size-base);
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  color: var(--font-light-color);
  padding: 20px 40px;
  cursor: pointer;

  &:disabled {
    background-color: var(--background-light);
    color: var(--primary-color);
    outline: none;
    cursor: not-allowed;
  }
  
  &:hover {
    background-color: var(--background-light);
    color: var(--primary-color);
    outline: 4px solid var(--primary-color);
  }

  @media (max-width: 799px) {
    font-size: var(--font-size-base-mobile);
    padding: 10px 20px;
  }
}
