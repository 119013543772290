.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
  gap: 50px;

  @media (max-width: 799px) {
    gap: 20px;
    margin: 20px 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.upperLabel {
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  color: var(--font-light-color);
  display: flex;
  flex-direction: column;
  text-align: justify;
  padding: 24px 48px;
  margin-left: -50%;

  & > h3 {
    margin: 0;
  }

  @media (max-width: 799px) {
    margin-left: 0;
    padding: 12px 30px;
  }
}

.upperImg {
  border-radius: var(--border-radius);
  width: 67%;
  margin-top: -40px;
  z-index: -1;

  @media (max-width: 799px) {
    margin-top: -5px;
    width: 80%
  }
}

.packContainer {
  border-radius: var(--border-radius);
  background-color: var(--background-medium);
  min-width: 46%;
  max-width: 46%;
  padding: 48px;
  text-align: center;

  & > h2 {
    text-transform:uppercase;
    font-size: var(--font-size-xxl);
    font-weight: 300;
    margin-top: 0;
  }

  & > p {
    margin: 20px 0;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--primary-color);
}

.packBox {
  outline: 4px solid var(--primary-color);
  border-radius: var(--border-radius);
  padding: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  & > div {
    & > p {
      text-align: center;
      margin: 10px 0;
    }

    & > h3 {
      margin: 5px 0;
      font-size: var(--font-size-base);
      font-weight: bold;
      text-decoration: underline;
      color: var(--primary-color)
    }
  }
}

.packPrice {
  width: 100%;
}
